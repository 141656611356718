import { Action } from "react-fetching-library"
import {
	Alert,
	AuditLog,
	BasicLabel,
	BasicName,
	CallLog,
	Car,
	CheckAvailability,
	ClientDetail,
	ClientHour,
	ClientWithContact,
	Country,
	Form,
	GraphData,
	Travel,
	Language,
	MileageClaim,
	Note,
	RemovedAssignedWorker,
	Role,
	Session,
	SessionsNote,
	Timesheet,
	UserDetail,
	UserWithRole,
	NDISPlan,
	NDISPlanLine,
	NDISLineItemManager,
	NDISLineItem,
	NDISPlanFile,
	Referrer,
	OfficeWithAddress,
	ContractTimeReport,
	AlertType,
	NoteType,
	ClientEmergencyContact,
	ClientParentGuardian,
	NDISPriceGuide,
	ClientHealthTreatmentTeam,
	SessionFundingSource,
	NDISItemGroup,
	ItemGroupDetail,
	ValueChargeDetails,
	ClientRelatedNote,
	SessionAllocatedLineItem,
	SessionNDISPlanLine,
	Gender,
	RelatedService,
	ServiceProvider,
	NoteTypeItem,
	CallPurpose,
	SupportType,
	ContractArea,
	FundingSource,
	SubSupportType,
	DeliveredProgram,
	RemovedAssignedClient,
	BlacklistUser,
} from "../types/types"
import {
	AuditLogSearchFilterInput,
	BasicSearchFilterInput,
	ClientRelatedSearchFilterInput,
	UserSearchFilterInput,
	CheckAvailabilityInput,
	TravelSearchFilterInput,
	MileageSearchFilterInput,
	SessionSearchFilterInput,
	TimesheetFilterInput,
	FormSearchFilterInput,
	ContractHoursInput,
	CheckSessionTravelAvailabilityInput,
	ClientHoursReportInput,
	NDISInvoiceReportInput,
	StaffHourReportInput,
	ContractHourReportInput,
	CallLogReportInput,
	BlackListSearchFilterInput,
	NDISUtilisationReportInput,
	YouthCareerReportInput,
} from "./inputType"
import { NDISLineItemType, NoteTypeCategory, RateVariant } from "../types/enums"

const getMe = (): Action<UserDetail> => {
	return {
		method: "GET",
		endpoint: `/users/me`,
		credentials: "include",
		responseType: "json",
	}
}

const check = (): Action<{ loggedIn: boolean }> => {
	return {
		method: "GET",
		endpoint: `/auth/check`,
		credentials: "include",
		responseType: "json",
	}
}

const getRoleAll = (): Action<Role[]> => {
	return {
		method: "GET",
		endpoint: `/roles`,
		credentials: "include",
		responseType: "json",
	}
}

const getTeamUsersAll = (): Action<{ users: UserDetail[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/users/team/all`,
		credentials: "include",
		responseType: "json",
	}
}

const getTeamUsers = (values: UserSearchFilterInput): Action<{ users: UserWithRole[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/users/team`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getManyUsers = (values: UserSearchFilterInput): Action<{ users: UserWithRole[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/users/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getBlacklistUsers = (values: BlackListSearchFilterInput): Action<{ blacklist: BlacklistUser[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/users/blacklist/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getUser = (id: string): Action<UserDetail> => {
	return {
		method: "GET",
		endpoint: `/users/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getClientMany = (values: UserSearchFilterInput & { showAllClients?: boolean }): Action<{ clients: ClientWithContact[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/clients/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getClientActiveNDISPlan = (id: string): Action<NDISPlan> => {
	return {
		method: "GET",
		endpoint: `/clients/${id}/ndis_plan`,
		credentials: "include",
		responseType: "json",
	}
}

const getClientActiveNDISPlanLineItems = (
	id: string,
	sessionID: string,
): Action<{ activeLineItems: SessionAllocatedLineItem[]; allocatedLineItems: SessionAllocatedLineItem[] }> => {
	return {
		method: "POST",
		endpoint: `/clients/${id}/ndis_plan/line_items`,
		credentials: "include",
		responseType: "json",
		body: {
			sessionID,
		},
	}
}

const getClientNDISPlans = (id: string): Action<NDISPlan[]> => {
	return {
		method: "GET",
		endpoint: `/ndis_plans?clientID=${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getClientNDISPlanLines = (id: string): Action<NDISPlanLine[]> => {
	return {
		method: "GET",
		endpoint: `/ndis_plans/${id}/lines`,
		credentials: "include",
		responseType: "json",
	}
}

const getNDISPlanManagers = (): Action<NDISLineItemManager[]> => {
	return {
		method: "GET",
		endpoint: `/ndis_plans/plan_managers`,
		credentials: "include",
		responseType: "json",
	}
}

const getNDISPlanManagerMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
	childOnly?: boolean
}): Action<{ total: number; planManagers: NDISLineItemManager[] }> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/plan_managers/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getNDISLineItems = (search: string, include?: NDISPlanLine): Action<NDISLineItem[]> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/line_items/search`,
		credentials: "include",
		responseType: "json",
		body: {
			search,
			includeID: include ? include.support.lineItem.id : undefined,
		},
	}
}

const getNDISPlanFiles = (planID: string): Action<NDISPlanFile[]> => {
	return {
		method: "GET",
		endpoint: `/ndis_plans/${planID}/files`,
		credentials: "include",
		responseType: "json",
	}
}

const getNDISPlanValueCharges = (values: { planLineID: string; chargeType: NDISLineItemType }): Action<ValueChargeDetails[]> => {
	return {
		method: "GET",
		endpoint: `/ndis_plans/${values.planLineID}/general_charges?charge_type=${values.chargeType}`,
		credentials: "include",
		responseType: "json",
	}
}

const getNDISPriceGuides = (): Action<NDISPriceGuide[]> => {
	return {
		method: "GET",
		endpoint: `/ndis_management/price_guides`,
		credentials: "include",
		responseType: "json",
	}
}

const getNDISLineItem = (id: string): Action<NDISLineItem> => {
	return {
		method: "GET",
		endpoint: `/ndis_management/line_items/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getNDISLineItemsByPriceGuide = (values: {
	priceGuideID: string
	search?: string
	filterBy?: string
	limit?: number
	offset?: number
	hourUnitOnly?: boolean
	rateVariant?: RateVariant
}): Action<{ lineItems: NDISLineItem[]; total: number }> => {
	const { priceGuideID, ...rest } = values
	return {
		method: "POST",
		endpoint: `/ndis_management/price_guides/${priceGuideID}/line_items/search`,
		credentials: "include",
		body: {
			...rest,
		},
		responseType: "json",
	}
}

const getItemGroupsByPriceGuide = (values: {
	priceGuideID: string
	search?: string
	filterBy?: string
	limit?: number
	offset?: number
}): Action<{ itemGroups: NDISItemGroup[]; total: number }> => {
	const { priceGuideID, ...rest } = values
	return {
		method: "POST",
		endpoint: `/ndis_management/price_guides/${priceGuideID}/item_groups/search`,
		credentials: "include",
		body: {
			...rest,
		},
		responseType: "json",
	}
}

const getItemGroup = (id: string): Action<ItemGroupDetail> => {
	return {
		method: "GET",
		endpoint: `/ndis_management/item_groups/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getLanguageAll = (): Action<Language[]> => {
	return {
		method: "GET",
		endpoint: `/languages`,
		credentials: "include",
		responseType: "json",
	}
}

const getLanguageMany = (values: { search: string; limit: number; offset: number; isArchived: boolean }): Action<{ languages: Language[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/languages/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getCountryAll = (): Action<Country[]> => {
	return {
		method: "GET",
		endpoint: `/countries`,
		credentials: "include",
		responseType: "json",
	}
}

const getReferrerAll = (): Action<Referrer[]> => {
	return {
		method: "GET",
		endpoint: `/referrers`,
		credentials: "include",
		responseType: "json",
	}
}

const getReferrerMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
	parentID?: string
}): Action<{ referrers: Referrer[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/referrers/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getInitialContactSourceAll = (): Action<BasicName[]> => {
	return {
		method: "GET",
		endpoint: `/initial_contact_sources`,
		credentials: "include",
		responseType: "json",
	}
}

const getServiceProviderAll = (): Action<BasicName[]> => {
	return {
		method: "GET",
		endpoint: `/service_providers`,
		credentials: "include",
		responseType: "json",
	}
}

const getServiceProviderMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ serviceProviders: ServiceProvider[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/service_providers/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getRelatedServiceAll = (): Action<BasicName[]> => {
	return {
		method: "GET",
		endpoint: `/related_services`,
		credentials: "include",
		responseType: "json",
	}
}

const getRelatedServiceMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ relatedServices: RelatedService[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/related_services/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getGenderAll = (): Action<BasicName[]> => {
	return {
		method: "GET",
		endpoint: `/genders`,
		credentials: "include",
		responseType: "json",
	}
}

const getGenderMany = (values: { search: string; limit: number; offset: number; isArchived: boolean }): Action<{ genders: Gender[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/genders/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getClient = (id: string): Action<ClientDetail> => {
	return {
		method: "GET",
		endpoint: `/clients/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getPlacePredictions = (searchText: string): Action<string[]> => {
	return {
		method: "GET",
		endpoint: `/maps/place_predictions?search_key=${searchText}`,
		credentials: "include",
		responseType: "json",
	}
}

const getTravel = (id: string): Action<Travel> => {
	return {
		method: "GET",
		endpoint: `/travels/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getCallLog = (id: string): Action<CallLog> => {
	return {
		method: "GET",
		endpoint: `/call_logs/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getCallLogMany = (values: BasicSearchFilterInput & { clientID?: string }): Action<{ callLogs: CallLog[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/call_logs/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getTravelMany = (values: TravelSearchFilterInput): Action<{ travels: Travel[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/travels/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getDistanceMatrix = (values: {
	fromAddressPlaceID: string
	toAddressPlaceID: string
	startTime?: Date
}): Action<{ distanceMeter: number; trafficMinute: number }> => {
	return {
		method: "POST",
		endpoint: `/maps/distance_matrix`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

export interface SessionListItem {
	id: string
	identifier: number
	date: string
	startTime: string
	endTime: string
	attendanceStatus: string
	workers: { id: string; firstName: string; lastName: string; avatarURL: string }[]
	clients: { id: string; firstName: string; lastName: string; avatarURL: string }[]
	location: string
	officeLocation: string
	fundingSource: string
	supportType: string
	sessionFundingSources: SessionFundingSource[]
	meetingType: string
	mentors: UserDetail[]
	deletedAt?: string
}

const getSessionMany = (values: SessionSearchFilterInput): Action<{ sessions: SessionListItem[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/sessions/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getClientSessionMany = (values: SessionSearchFilterInput): Action<{ sessions: SessionListItem[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/sessions/client`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getSessionByIdentifer = (identifier: number): Action<Session> => {
	return {
		method: "GET",
		endpoint: `/sessions/identifier/${identifier}`,
		credentials: "include",
		responseType: "json",
	}
}

const getSessionPlanLines = (id: string): Action<NDISPlanLine> => {
	return {
		method: "GET",
		endpoint: `/sessions/${id}/plan_line`,
		credentials: "include",
		responseType: "json",
	}
}

const getFundingSourceAll = (omitNDIA?: boolean, includeNonBillable?: boolean): Action<FundingSource[]> => {
	const params: string[] = []
	if (omitNDIA) params.push("omit_NDIA=true")
	if (includeNonBillable) params.push("include_non_billable=true")
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources?${params.join("&")}`,
		credentials: "include",
		responseType: "json",
	}
}

const getFundingSourceWithGroupClientSupport = (): Action<FundingSource[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources/with_group_client_support`,
		credentials: "include",
		responseType: "json",
	}
}

const getFundingSourceMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ fundingSources: FundingSource[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getNDISContractAreas = (): Action<ContractArea[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources/ndis_contract_areas`,
		credentials: "include",
		responseType: "json",
	}
}

const getContractAreasByFundingSourceID = (id: string): Action<ContractArea[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources/${id}/contract_areas`,
		credentials: "include",
		responseType: "json",
	}
}

const getContractAreaMany = (values: {
	fundingSourceID: string
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ contractAreas: ContractArea[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/contract_areas/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getSupportTypesByFundingSourceID = (id: string): Action<SupportType[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources/${id}/support_types`,
		credentials: "include",
		responseType: "json",
	}
}

const getSupportTypeMany = (values: {
	fundingSourceID: string
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ supportTypes: SupportType[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getNonBillableSupportTypes = (): Action<SupportType[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources/non-billable/support_types`,
		credentials: "include",
		responseType: "json",
	}
}

const getNonBillableSubSupportTypes = (values: { supportTypeID: string }): Action<SubSupportType[]> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/non-billable/sub_support_types`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getNonBillableSubSubSupportTypes = (values: { subSupportTypeID: string }): Action<SubSupportType[]> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/non-billable/sub_sub_support_types`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getSubSubSupportTypesBySubSupportTypeID = (values: { fundingSourceID: string; supportTypeID: string; subSupportTypeID: string }): Action<SubSupportType[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/${values.subSupportTypeID}/sub_sub_support_types`,
		credentials: "include",
		responseType: "json",
	}
}

const getSubSupportTypesBySupportTypeID = (values: { fundingSourceID: string; supportTypeID: string }): Action<SubSupportType[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types`,
		credentials: "include",
		responseType: "json",
	}
}

const getSubSupportTypeMany = (values: {
	fundingSourceID: string
	supportTypeID: string
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ subSupportTypes: SubSupportType[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getSubSubSupportTypeMany = (values: {
	fundingSourceID: string
	supportTypeID: string
	subSupportTypeID: string
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ subSupportTypes: SubSupportType[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${values.fundingSourceID}/support_types/${values.supportTypeID}/sub_support_types/${values.subSupportTypeID}/sub_sub_support_types/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getContractHours = (values: ContractHoursInput): Action<ContractTimeReport[]> => {
	return {
		method: "POST",
		endpoint: "/sessions/contract_hours",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getClientHours = (values: ClientHoursReportInput): Action<Blob> => {
	return {
		method: "POST",
		endpoint: "/admin/client_hours",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "blob",
	}
}

const getNDISInvoiceReport = (values: NDISInvoiceReportInput): Action<Blob> => {
	return {
		method: "POST",
		endpoint: "/reports/ndis_invoice",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "blob",
	}
}

const getStaffHourReport = (values: StaffHourReportInput): Action<Blob> => {
	return {
		method: "POST",
		endpoint: "/reports/staff_hours",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "blob",
	}
}

const getContractHourReport = (values: ContractHourReportInput): Action<Blob> => {
	return {
		method: "POST",
		endpoint: "/reports/contract_hours",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "blob",
	}
}

interface FundingSourceReportInput {
	sessionType: "SINGLE" | "GROUP"
	fundingSourceID: string
	contractAreaID: string
	workerID: string | undefined
	startDate: Date
	endDate: Date
}

const getFundingSourceReport = (values: FundingSourceReportInput): Action<Blob> => {
	return {
		method: "POST",
		endpoint: "/admin/generate_funding_source_report",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "blob",
	}
}

const getCallLogReport = (values: CallLogReportInput): Action<Blob> => {
	return {
		method: "POST",
		endpoint: "/reports/call_logs",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "blob",
	}
}

const verifyResetToken = (token: string): Action<boolean> => {
	return {
		method: "GET",
		endpoint: `/users/verify_reset_token/${token}`,
		credentials: "include",
		responseType: "json",
	}
}

const getClientRelatedNotesMany = (values: ClientRelatedSearchFilterInput): Action<{ relatedNotes: ClientRelatedNote[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/clients/notes/related/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getClientNotesMany = (values: ClientRelatedSearchFilterInput): Action<{ notes: Note[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/clients/notes/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getClientAlertMany = (values: ClientRelatedSearchFilterInput): Action<{ alertList: Alert[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/alerts/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getOfficeAll = (): Action<BasicName[]> => {
	return {
		method: "GET",
		endpoint: `/offices`,
		credentials: "include",
		responseType: "json",
	}
}
const getOfficeMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ offices: OfficeWithAddress[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/offices/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getCallLogCountInWeek = (): Action<number> => {
	return {
		method: "GET",
		endpoint: `/call_logs/count_in_week`,
		credentials: "include",
		responseType: "json",
	}
}

const getClientHourList = (durationOption?: string): Action<ClientHour[]> => {
	return {
		method: "GET",
		endpoint: `/clients/hours?duration=${durationOption}`,
		credentials: "include",
		responseType: "json",
	}
}

const getTravelStatistics = (durationOption: string): Action<GraphData[]> => {
	return {
		method: "GET",
		endpoint: `/travels/statistic?duration=${durationOption}`,
		credentials: "include",
		responseType: "json",
	}
}

const getForm = <T>(id: string): Action<Form<T>> => {
	return {
		method: "GET",
		endpoint: `/forms/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const AuditLogGet = (id: string): Action<AuditLog> => {
	return {
		method: "GET",
		endpoint: `/audit_logs/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getAuditLogMany = (values: AuditLogSearchFilterInput): Action<{ auditLogs: AuditLog[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/audit_logs/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getTravelTypeAll = (): Action<{ id: string; label: string; code: string }[]> => {
	return {
		method: "GET",
		endpoint: `/travel_types`,
		credentials: "include",
		responseType: "json",
	}
}

const getCarAll = (): Action<Car[]> => {
	return {
		method: "GET",
		endpoint: `/cars`,
		credentials: "include",
		responseType: "json",
	}
}

const getMileageClaimMany = (values: MileageSearchFilterInput): Action<{ mileageClaims: MileageClaim[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/mileage_claims/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getMileageClaim = (id: string): Action<MileageClaim> => {
	return {
		method: "GET",
		endpoint: `/mileage_claims/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getMileageClaimTravels = (id: string): Action<Travel[]> => {
	return {
		method: "GET",
		endpoint: `/mileage_claims/${id}/travels`,
		credentials: "include",
		responseType: "json",
	}
}

const getDepartmentAll = (): Action<BasicName[]> => {
	return {
		method: "GET",
		endpoint: `/departments`,
		credentials: "include",
		responseType: "json",
	}
}

const getDepartmentMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ departments: BasicName[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/departments/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getPositionAll = (departmentID: string): Action<BasicName[]> => {
	return {
		method: "GET",
		endpoint: `/positions?department_id=${departmentID}`,
		credentials: "include",
		responseType: "json",
	}
}

const getPositionMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
	departmentID: string
}): Action<{ positions: BasicName[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/positions/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getSessionNotes = (id: string): Action<SessionsNote[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/${id}/notes`,
		credentials: "include",
		responseType: "json",
	}
}

const getSessionAllocatedNDISPlanLines = (id: string): Action<SessionNDISPlanLine[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/${id}/allocated_ndis_plan_lines`,
		credentials: "include",
		responseType: "json",
	}
}

const getClientWorkerHistoryByClientID = (id: string): Action<RemovedAssignedWorker[]> => {
	return {
		method: "GET",
		endpoint: `/clients/${id}/client_worker_history`,
		credentials: "include",
		responseType: "json",
	}
}

const getClientWorkerHistoryByWorkerID = (id: string): Action<RemovedAssignedClient[]> => {
	return {
		method: "GET",
		endpoint: `/users/${id}/client_worker_history`,
		credentials: "include",
		responseType: "json",
	}
}

const checkWorkerAvailability = (values: CheckAvailabilityInput): Action<CheckAvailability> => {
	return {
		method: "POST",
		endpoint: `/users/check_availability`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const checkSessionTravelAvailability = (values: CheckSessionTravelAvailabilityInput): Action<CheckAvailability> => {
	return {
		method: "POST",
		endpoint: `/users/check_session_travel_availability`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const checkEmailAvailability = (email: string): Action<CheckAvailability> => {
	return {
		method: "POST",
		endpoint: `/users/check_email_availability`,
		credentials: "include",
		body: {
			email,
		},
		responseType: "json",
	}
}

const getCallPurposeAll = (): Action<CallPurpose[]> => {
	return {
		method: "GET",
		endpoint: `/call_logs/call_purposes`,
		credentials: "include",
		responseType: "json",
	}
}

const getCallPurposeMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ callPurposes: CallPurpose[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getCallPurposeTypesByCallPurposeID = (id: string): Action<BasicLabel[]> => {
	return {
		method: "GET",
		endpoint: `/call_logs/call_purposes/${id}/call_purpose_types`,
		credentials: "include",
		responseType: "json",
	}
}

const getCallPurposeTypeMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
	callPurposeID: string
}): Action<{ callPurposeTypes: CallPurpose[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/call_logs/call_purposes/${values.callPurposeID}/call_purpose_types/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getManyTimesheets = (values: TimesheetFilterInput): Action<{ timesheets: Timesheet[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/timesheets/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

const getFormsMany = (values: FormSearchFilterInput): Action<{ formList: Form<any>[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/forms/many`,
		credentials: "include",
		body: {
			...values,
		},
		responseType: "json",
	}
}

export interface TimesheetDayData {
	date: string
	originalPayableTime: number
	timePayedDayMins: number
	rows: TimesheetRow[]
	multiplier: string
	equation: string
}

export interface TimesheetRow {
	timezoneID: string
	startTime: string
	endTime: string
	timePayedMins: number
	description: string
	session: Session
	travel: Travel
	isNonBillable: boolean
}

export interface GeneratedTimesheetResult {
	daysData: TimesheetDayData[]
}
const getTimesheet = (id: string): Action<{ timesheet: Timesheet; generatedTimesheet: GeneratedTimesheetResult }> => {
	return {
		method: "GET",
		endpoint: `/timesheets/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const lockDateGet = (): Action<{ date: string; updatedAt: string }> => {
	return {
		method: "GET",
		endpoint: `/admin/lock_date`,
		credentials: "include",
		responseType: "json",
	}
}

const changeLogGet = (): Action<{ version: string; logs: string[] }[]> => {
	return {
		method: "GET",
		endpoint: `/admin/change_logs`,
		credentials: "include",
		responseType: "json",
	}
}

const getIncompleteSession = (id: string): Action<Session> => {
	return {
		method: "GET",
		endpoint: `/sessions/incomplete/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const getHasDeclinedReports = (id: string): Action<Session> => {
	return {
		method: "GET",
		endpoint: `/users/${id}/has_declined_reports`,
		credentials: "include",
		responseType: "json",
	}
}

const getOfficeWithAddress = (id: string): Action<OfficeWithAddress> => {
	return {
		method: "GET",
		endpoint: `/offices/${id}`,
		credentials: "include",
		responseType: "json",
	}
}

const checkClientGroupLineItem = (values: { clientIDList: string[]; bookingDate: Date }): Action<string[]> => {
	return {
		method: "POST",
		endpoint: `/clients/check_group_plan_line`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getAlertTypes = (): Action<AlertType[]> => {
	return {
		method: "GET",
		endpoint: `/alerts/types`,
		credentials: "include",
		responseType: "json",
	}
}

const getAlertTypeMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ alertTypes: AlertType[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getAlertSubtypes = (id: string): Action<AlertType[]> => {
	return {
		method: "GET",
		endpoint: `/alerts/types/${id}/subtypes`,
		credentials: "include",
		responseType: "json",
	}
}

const getAlertSubtypeMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
	alertTypeID: string
}): Action<{ alertSubtypes: AlertType[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/alerts/types/${values.alertTypeID}/subtypes/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const callLogNotesGet = (id: string): Action<Note[]> => {
	return {
		method: "GET",
		endpoint: `/call_logs/${id}/notes`,
		credentials: "include",
		responseType: "json",
	}
}

const noteTypeMany = (isClient: boolean, sessionID?: string): Action<NoteType[]> => {
	return {
		method: "POST",
		endpoint: `/notes/types`,
		credentials: "include",
		responseType: "json",
		body: {
			isClient,
			sessionID,
		},
	}
}

const noteTypeManyFromCategory = (values: {
	category: NoteTypeCategory
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ noteTypeItems: NoteTypeItem[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/notes/types/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const recordViewAction = (clientID: string): Action<boolean> => {
	return {
		method: "GET",
		endpoint: `/clients/${clientID}/record_view`,
		credentials: "include",
		responseType: "json",
	}
}

const clientEmergencyContactMany = (values: { clientID: string; isArchived: boolean }): Action<ClientEmergencyContact[]> => {
	return {
		method: "POST",
		endpoint: `/clients/emergency_contact/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const clientParentGuardianContactMany = (values: { clientID: string; isArchived: boolean }): Action<ClientParentGuardian[]> => {
	return {
		method: "POST",
		endpoint: `/clients/parent_guardian_contact/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const clientHealthTreatmentTeamGet = (values: { clientID: string; isArchived: boolean }): Action<ClientHealthTreatmentTeam[]> => {
	return {
		method: "POST",
		endpoint: `/clients/health_teams`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const sessionTravelsGet = (values: { sessionID: string; activeOnly?: boolean }): Action<Travel[]> => {
	return {
		method: "POST",
		endpoint: `/sessions/travels`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const carMany = (values: { search: string; limit: number; offset: number; isArchived: boolean }): Action<{ cars: Car[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/cars/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getDeliveredProgramAll = (): Action<DeliveredProgram[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/delivered_programs/`,
		credentials: "include",
		responseType: "json",
	}
}

const getDeliveredProgramMany = (values: {
	search: string
	limit: number
	offset: number
	isArchived: boolean
}): Action<{ deliveredPrograms: DeliveredProgram[]; total: number }> => {
	return {
		method: "POST",
		endpoint: `/sessions/delivered_programs/many`,
		credentials: "include",
		responseType: "json",
		body: values,
	}
}

const getNDISUtilisation = (values: NDISUtilisationReportInput): Action<Blob> => {
	return {
		method: "POST",
		endpoint: "/reports/ndis_utilisation",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "blob",
	}
}

const getContractAreaOptions = (id?: string): Action<ContractArea[]> => {
	return {
		method: "GET",
		endpoint: `/sessions/funding_sources/${id}/contract_areas/options`,
		credentials: "include",
		responseType: "json",
	}
}

const getContractAreaClientOptions = (search: string, include?: {id: string}, id?: string): Action<{id: string, deletedAt?: string}[]> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/${id}/contract_areas/client_options`,
		credentials: "include",
		responseType: "json",
		body: {
			search,
			includeID: include ? include.id : undefined,
		},
	}
}

const getPRCOptions = (search: string[], include?: {id: string}): Action<{id: string, deletedAt?: string}[]> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/ndis_line_items/prc_support/options`,
		credentials: "include",
		responseType: "json",
		body: {
			search,
			includeID: include ? include.id : undefined,
		},
	}
}

const getCoreSupportOptions = (search: string[], include?: {id: string}): Action<{id: string, deletedAt?: string}[]> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/ndis_line_items/core_support/search/options`,
		credentials: "include",
		responseType: "json",
		body: {
			search,
			includeID: include ? include.id : undefined,
		},
	}
}

const getSupportCoordinationOptions = (search: string[], include?: {id: string}): Action<{id: string, deletedAt?: string}[]> => {
	return {
		method: "POST",
		endpoint: `/ndis_plans/ndis_line_items/support_coordination/search/options`,
		credentials: "include",
		responseType: "json",
		body: {
			search,
			includeID: include ? include.id : undefined,
		},
	}
}

const youthCarerReport = (values: YouthCareerReportInput): Action<Blob> => {
	return {
		method: "POST",
		endpoint: "/reports/youth_career",
		credentials: "include",
		body: {
			...values,
		},
		responseType: "blob",
	}
}

const getFundingTypeOptions = (search: string[], include?: {id: string}): Action<{id: string, deletedAt?: string}[]> => {
	return {
		method: "POST",
		endpoint: `/sessions/funding_sources/search/options`,
		credentials: "include",
		responseType: "json",
		body: {
			search,
			includeID: include ? include.id : undefined,
		},
	}
}

export const query = {
	getMe,
	check,
	getRoleAll,
	getManyUsers,
	getTeamUsers,
	getTeamUsersAll,
	getUser,
	getClientMany,
	getLanguageAll,
	getCountryAll,
	getReferrerAll,
	getInitialContactSourceAll,
	getServiceProviderAll,
	getRelatedServiceAll,
	getGenderAll,
	getClient,
	getPlacePredictions,
	getTravel,
	getCallLog,
	getCallLogMany,
	getTravelMany,
	getDistanceMatrix,
	getSessionMany,
	getClientSessionMany,
	getSessionByIdentifer,
	getFundingSourceAll,
	getContractAreasByFundingSourceID,
	getSupportTypesByFundingSourceID,
	getSubSupportTypesBySupportTypeID,
	getSubSubSupportTypesBySubSupportTypeID,
	getContractHours,
	verifyResetToken,
	getClientNotesMany,
	getClientAlertMany,
	getOfficeAll,
	getCallLogCountInWeek,
	getClientHourList,
	getTravelStatistics,
	getForm,
	AuditLogGet,
	getAuditLogMany,
	getTravelTypeAll,
	getCarAll,
	getMileageClaimMany,
	getMileageClaim,
	getDepartmentAll,
	getPositionAll,
	getClientWorkerHistoryByClientID,
	checkWorkerAvailability,
	checkSessionTravelAvailability,
	getCallPurposeAll,
	getCallPurposeTypesByCallPurposeID,
	getFormsMany,
	getSessionNotes,
	getMileageClaimTravels,
	getManyTimesheets,
	getTimesheet,
	getClientNDISPlans,
	getClientNDISPlanLines,
	getNDISPlanFiles,
	getNDISPlanManagers,
	getNDISLineItems,
	checkEmailAvailability,
	getClientActiveNDISPlan,
	getClientActiveNDISPlanLineItems,
	getSessionPlanLines,
	getNonBillableSupportTypes,
	getIncompleteSession,
	getHasDeclinedReports,
	getOfficeWithAddress,
	checkClientGroupLineItem,
	getAlertTypes,
	getAlertSubtypes,
	noteTypeMany,
	callLogNotesGet,
	recordViewAction,
	clientEmergencyContactMany,
	clientParentGuardianContactMany,
	getNDISPriceGuides,
	getNDISLineItemsByPriceGuide,
	getNDISLineItem,
	clientHealthTreatmentTeamGet,
	sessionTravelsGet,
	lockDateGet,
	getClientHours,
	getNDISInvoiceReport,
	getItemGroupsByPriceGuide,
	getItemGroup,
	getNDISPlanValueCharges,
	getClientRelatedNotesMany,
	getSessionAllocatedNDISPlanLines,
	getNDISContractAreas,
	carMany,
	getLanguageMany,
	getGenderMany,
	getRelatedServiceMany,
	getServiceProviderMany,
	getOfficeMany,
	noteTypeManyFromCategory,
	getNDISPlanManagerMany,
	getReferrerMany,
	getAlertTypeMany,
	getAlertSubtypeMany,
	getDepartmentMany,
	getPositionMany,
	getCallPurposeMany,
	getCallPurposeTypeMany,
	getFundingSourceMany,
	getContractAreaMany,
	getSupportTypeMany,
	getSubSupportTypeMany,
	getSubSubSupportTypeMany,
	getDeliveredProgramAll,
	getDeliveredProgramMany,
	getNonBillableSubSupportTypes,
	getNonBillableSubSubSupportTypes,
	getClientWorkerHistoryByWorkerID,
	getStaffHourReport,
	getContractHourReport,
	changeLogGet,
	getCallLogReport,
	getFundingSourceWithGroupClientSupport,
	getFundingSourceReport,
	getBlacklistUsers,
	getNDISUtilisation,
	getContractAreaOptions,
	getContractAreaClientOptions,
	getPRCOptions,
	getCoreSupportOptions,
	getSupportCoordinationOptions,
	youthCarerReport,
	getFundingTypeOptions,
}
