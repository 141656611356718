import React, {useEffect, useState} from 'react';
import NDISUtilisationContext from './context';
import { useForm } from "react-hook-form";
import { Option, Value } from "baseui/select";
import { FormValues, Props, Values, Actions } from './types';
import { capitalize, getErrorFromBlob, snakeToTitle, useDebounce } from 'helpers/utils';
import { useParameterizedQuery, useQuery, } from "react-fetching-library";
import { fetching } from 'fetching';
import moment from 'moment';


const NDISUtilisationProvider = (props: Props) => {

    const { children } = props ?? {};
    const { control, handleSubmit, errors, getValues, setValue } = useForm<FormValues>();

	const { query: NDISUlitisationQuery, loading, error: ndisUtilisationPayloadError } = useParameterizedQuery(fetching.query.getNDISUtilisation);
    const contractAreaOptions = useQuery(fetching.query.getContractAreaOptions());
    
	const [searchKey, setSearchKey] = React.useState<any>("");
	const [lineItemDisplayOptions, setLineItemDisplayOptions] = React.useState<string>("");
	const debouncedSearchTerm = useDebounce(lineItemDisplayOptions, 500);
	React.useEffect(() => setSearchKey(debouncedSearchTerm), [debouncedSearchTerm]);
    
    const [contractAreaLabel, setContractAreaLabel] = React.useState<string>("");
	const [contractArea, setContractArea] = React.useState<string>("");
    const debouncedAreaLabel = useDebounce(contractArea, 500);
    React.useEffect(() => setContractAreaLabel(debouncedAreaLabel), [debouncedAreaLabel]);
    
    const {query: serchClientOptions, payload: clientItems, loading: clientOptLoading} = useQuery(
        fetching.query.getContractAreaClientOptions(contractAreaLabel)
    );
    const {query: searchPrcOptions, payload: prcSupportItems, loading: prcSupportLoading} = useQuery(
        fetching.query.getPRCOptions(searchKey)
    );
    const { query: searchCoreSupportOptions, payload: coreSupportItems, loading: coreSupportItemsLoading, error: coreSupportItemsError } = useQuery(
		fetching.query.getCoreSupportOptions(searchKey),
	);
    const { query: searchSupportCoordinationOptions, payload: supportCoordinationItems, loading: supportCoordinationItemsLoading, error: supportCoordinationItemsError } = useQuery(
		fetching.query.getSupportCoordinationOptions(searchKey),
	);

    React.useEffect(() => {
		if (searchKey === "") return
        searchPrcOptions()
		searchCoreSupportOptions()
        searchSupportCoordinationOptions()
        
	}, [searchKey, searchPrcOptions, searchCoreSupportOptions, searchSupportCoordinationOptions]);
    React.useEffect(() => {
        if (contractAreaLabel === "") return
        serchClientOptions()
    }, [contractAreaLabel])
    React.useEffect(() => {
        setValue("startDate", (new Date()).toDateString())
    }, [])

    const [data, setData] = useState<Option[]>();
    const [contractAreaOpt, setContractAreaOpt] = useState<Option[]>();
    const [prcOpt, setPrcOpt] = useState<Option[]>();
    const [coreSupportOpt, setCoreSupportOpt] = useState<Option[]>();
    const [supportCoordOpt, setSupportCoordOpt] = useState<Option[]>();
    const [isIncludedInUtilisation, setIsIncludedInUtilisation] = useState<boolean>(true);
    const [clientOpt, setClientOpt] = useState<Option[]>();
    const [errorMsg, setErrorMsg] = useState<Record<string, any>>({});
	const [durationWarning, setDurationWarning] = React.useState<string>();
    
    
    const handleOnSubmit = async (data: FormValues) => {
        console.log("Form Data on Submit:", data);
        const { client } = data ?? {}
        const { firstName='', lastName='', id: clientID=""} = client?.[0] ?? {}
        // if (
		// 	moment.duration(moment(data.endDate).diff(moment(data.startDate))) > moment.duration(10, "year") 
		// ) {
		// 	setDurationWarning("Report period is longer than 5 year.")
		// 	return
		// };
		setDurationWarning(undefined);
        setErrorMsg({});

        const isIncludedInUtilisation = data.isIncludedInUtilisation?.[0]?.id === "true";
        const response = await NDISUlitisationQuery({
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            contactArea: { 
              id: data?.contact_area?.[0]?.id?.toString() || "", 
              label: data?.contact_area?.[0]?.label?.toString() || ""
            },
            client: { 
              id: data.client?.[0]?.id?.toString() || "", 
              fullName: `${data.client?.[0]?.firstName || ''} ${data.client?.[0]?.lastName || ''}`.trim() 
            },
            prcSupportCodes: data?.prc_support_code?.map((item) => item.item_number?.toString() || ""),
            coreSupports: data?.core_supports?.map((item) => item.item_number?.toString() || ""),
            supportCoordinations: data?.support_coordination?.map((item) => item.item_number?.toString() || ""),
            isIncludedInUtilisation: isIncludedInUtilisation, // Now a boolean
          });

        console.log("Form Data on Submit (isIncludedInUtilisation):", data.isIncludedInUtilisation);


        if (response.error || !response.payload) {
            const err_msg = await getErrorFromBlob(response.payload)
			err_msg && setErrorMsg(
                (prev: Record<string, any>) => ({
                    ...prev,
                    eblobErr: err_msg
                })
            )
			return;
		}

        // Create link and initiate download when response is received
		const downloadLink = document.createElement("a")
		downloadLink.href = URL.createObjectURL(response.payload)
		downloadLink.download = `NDIS-Utilisation-Report-${moment(data.startDate).format("YYYYMMDD")}_${moment(data.endDate).format("YYYYMMDD")}.xlsx`
		downloadLink.click()
        return;
    };

    const handleInputChange = (value: Option[]) => {
        const newValue = value[value?.length - 1];
    };

    useEffect(() => {
        const contract_area_options = contractAreaOptions.payload;
    
        setContractAreaOpt(contract_area_options);
        setPrcOpt(prcSupportItems);
        setCoreSupportOpt(coreSupportItems);
        setSupportCoordOpt(supportCoordinationItems);
        setClientOpt(clientItems);
    
    }, [contractAreaOptions, prcSupportItems, coreSupportItems, supportCoordinationItems, clientItems, setValue]);
    
    const values = {
        data,
        contractAreaOpt,
        prcOpt,
        coreSupportOpt,
        supportCoordOpt,
        clientOpt,
        durationWarning,
        loading,
        prcSupportLoading,
        coreSupportItemsLoading,
        supportCoordinationItemsLoading,
        ndisUtilisationPayloadError,
    } as Values;

    const actions = {
        handleOnSubmit,
        setData,
        setContractAreaOpt,
        setLineItemDisplayOptions,
        setContractArea,
        // handleInputChange,
    } as Actions;

    const useFormHooks = {
        control,
        handleSubmit,
        errors,
        getValues,
        setValue,  // Add this here
    };

    return (
        <NDISUtilisationContext.Provider
            value={{
                errorMsg,
                values,
                actions,
                useFormHooks,
            }}
        >
            {children}
        </NDISUtilisationContext.Provider>
    )
};

export default NDISUtilisationProvider;