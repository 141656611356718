import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useStyletron } from "baseui"
import { ListItem, ListItemLabel } from "baseui/list"
import { Route, Switch, useHistory } from "react-router-dom"

import { ZenCard } from "../../components/common"
import { ZenTheme } from "../../themeOverrides"
import { ContractHoursReport } from "./contractHours"
import { Demographics } from "./demographics"
import { MileageClaims, MileageClaimManage } from "./mileageClaim"
import { NDISReport } from "./ndisInvoce"
import { CallLogReport } from "./callLogReport"
import { StaffHoursReport } from "./staffHours"
import { SMSReport } from "./smsReport"
import { TimesheetManage, TimeSheets } from "./timeSheets"
import { routes } from "routes"
import { NDISManagement } from "./ndisManagement/ndisManagement"
import { HeadingXSmall } from "baseui/typography"
import { RolePermission } from "../../types/types"
import { AuthContainer } from "../../controllers/auth"
import { ImportPriceGuide } from "./ndisManagement/importPriceGuide"
import { LockDateManagement } from "./lockDate"
import { ChangeLogs } from "./changeLogs"
import { ClientHoursReport } from "./clientHours"
import { NDISUtilisation } from "./ndisUtilisation"
import { YouthCareer } from "./youthCareer"
import { RegenerateCharges } from "./regenerateCharges"
import { RecalculateLimits } from "./recalculateLimits"
import { SelectableOptionManagement } from "./selectableOptionManagement"
import { CarOptionManagement } from "./selectableOptions/carOptionManagement"
import { CallPurposeOptionManagement } from "./selectableOptions/callPurposeOptionManagement"
import { LanguageOptionManagement } from "./selectableOptions/languageOptionManagement"
import { GenderOptionManagement } from "./selectableOptions/genderOptionManagement"
import { FundingSourceOptionManagement } from "./selectableOptions/fundingSourceOptionManagement"
import { RelatedServiceOptionManagement } from "./selectableOptions/relatedServiceOptionManagement"
import { ServiceProviderOptionManagement } from "./selectableOptions/serviceProviderOptionManagement"
import { OfficeOptionManagement } from "./selectableOptions/officeOptionManagement"
import { NoteTypeOptionManagement } from "./selectableOptions/noteTypeOptionManagement"
import { NDISPlanManagerOptionManagement } from "./selectableOptions/ndisPlanManagerOptionManagement"
import { ReferrerOptionManagement } from "./selectableOptions/referrerOptionManagement"
import { AlertTypeOptionManagement } from "./selectableOptions/alertTypeOptionManagement"
import { DepartmentPositionOptionManagement } from "./selectableOptions/departmentPositionOptionManagement"
import { DeliveredProgramOptionManagement } from "./selectableOptions/deliveredProgramOptionManagement"
import { GenerateFundingSourceReport } from "./selectableOptions/generateFundingSourceReport"

export default function ReportRoot() {
	const history = useHistory()

	return (
		<Switch>
			<Route exact path={routes.admin.root} component={Root} />
			<Route exact path={routes.admin.mileage.root} component={MileageClaims} />
			<Route
				exact
				path={routes.admin.mileage.create}
				render={() => <MileageClaimManage onSuccess={() => history.push(routes.admin.mileage.root)} onCancel={() => history.goBack()} />}
			/>

			<Route exact path={routes.admin.timesheets.root} component={TimeSheets} />
			<Route
				exact
				path={routes.admin.timesheets.create}
				render={() => <TimesheetManage onSuccess={() => history.push(routes.admin.timesheets.root)} onCancel={() => history.goBack()} />}
			/>
			<Route exact path={routes.admin.ndisInvoice} component={NDISReport} />
			<Route exact path={routes.admin.callLogs} component={CallLogReport} />
			<Route exact path={routes.admin.staffHours} component={StaffHoursReport} />
			<Route exact path={routes.admin.regenerateCharges} component={RegenerateCharges} />
			<Route exact path={routes.admin.recalculateLimits} component={RecalculateLimits} />
			<Route exact path={routes.admin.smsReminder} component={SMSReport} />
			<Route exact path={routes.admin.demographics} component={Demographics} />
			<Route exact path={routes.admin.contractHours} component={ContractHoursReport} />
			<Route exact path={routes.admin.ndisManagement.root} component={NDISManagement} />
			<Route exact path={routes.admin.ndisManagement.import} component={ImportPriceGuide} />
			<Route exact path={routes.admin.lockDateManagement} component={LockDateManagement} />
			<Route exact path={routes.admin.changeLogs} component={ChangeLogs} />
			<Route exact path={routes.admin.clientHours} component={ClientHoursReport} />
			<Route exact path={routes.admin.ndisUlilisation} component={NDISUtilisation} />
			<Route exact path={routes.admin.youthCareer} component={YouthCareer} />

			{/* Selectable Options Management */}
			<Route exact path={routes.admin.selectableOptionManagement.root} component={SelectableOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.alertTypes} component={AlertTypeOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.callPurposes} component={CallPurposeOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.cars} component={CarOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.departmentsPositions} component={DepartmentPositionOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.deliveredPrograms} component={DeliveredProgramOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.fundingSources} component={FundingSourceOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.genders} component={GenderOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.languages} component={LanguageOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.ndisPlanManagers} component={NDISPlanManagerOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.noteTypes} component={NoteTypeOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.offices} component={OfficeOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.referrers} component={ReferrerOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.relatedServices} component={RelatedServiceOptionManagement} />
			<Route exact path={routes.admin.selectableOptionManagement.serviceProviders} component={ServiceProviderOptionManagement} />

			{/* Generate funding source report */}
			<Route exact path={routes.admin.generateFundingSourceReport.root} component={GenerateFundingSourceReport} />


		</Switch>
	)
}

const Root = () => {
	const { hasPermission } = AuthContainer.useContainer()

	return (
		<ZenCard style={{ height: "100%" }}>
			<div style={{ overflow: "auto" }}>
				{hasPermission(RolePermission.ReportRead) && (
					<>
						<HeadingXSmall marginTop={0} marginBottom={"10px"}>
							Reports
						</HeadingXSmall>
						<ReportItem label={"Mileage Claims"} url={routes.admin.mileage.root} />
						<ReportItem label={"Timesheets"} url={routes.admin.timesheets.root} />
						<ReportItem label={"Call Logs"} url={routes.admin.callLogs} />
						{hasPermission(RolePermission.ReportCreate) && (
							<>
								<ReportItem label={"Session SMS Reminder"} url={routes.admin.smsReminder} />
								<ReportItem label={"Demographics"} url={routes.admin.demographics} />
								<ReportItem label={"Contract Hours"} url={routes.admin.contractHours} />
								<ReportItem label={"Staff Hours"} url={routes.admin.staffHours} />
								<ReportItem label={"Generate Funding Source Report"} url={routes.admin.generateFundingSourceReport.root} />
								<ReportItem label={"NDIS Invoice Report"} url={routes.admin.ndisInvoice} />
								<ReportItem label={"NDIS Client Hours"} url={routes.admin.clientHours} />
								<ReportItem label={"NDIS Utilisation"} url={routes.admin.ndisUlilisation} />
								<ReportItem label={"Pre and Post Outcomes Report"} url={routes.admin.youthCareer} />
							</>
						)}
					</>
				)}

				<>
					<HeadingXSmall marginTop={"20px"} marginBottom={"10px"}>
						Administration
					</HeadingXSmall>
					{hasPermission(RolePermission.NDISAdmin) && <ReportItem label={"NDIS Management"} url={routes.admin.ndisManagement.root} />}
					<ReportItem label={"Lock Date Management"} url={routes.admin.lockDateManagement} />
					{hasPermission(RolePermission.SelectableOptionManage) && (
						<ReportItem label={"Selectable Options Management"} url={routes.admin.selectableOptionManagement.root} />
					)}
					{hasPermission(RolePermission.TriggerRecalculate) && (
						<>
							<ReportItem label={"Recalculate NDIS Charges"} url={routes.admin.regenerateCharges} />
							<ReportItem label={"Recalculate Travel Limits"} url={routes.admin.recalculateLimits} />
						</>
					)}
					<ReportItem label={"Change Logs"} url={routes.admin.changeLogs} />
				</>
			</div>
		</ZenCard>
	)
}

export const ReportItem = (props: { url: string; label: string }) => {
	const [, theme] = useStyletron()
	const history = useHistory()
	return (
		<div onClick={() => history.push(props.url)}>
			<ListItem
				artwork={() => <FontAwesomeIcon icon={["fas", "arrow-right"]} size="1x" color={theme.colors.primary} />}
				overrides={{
					Root: {
						style: {
							cursor: "pointer",
							":hover": {
								backgroundColor: ZenTheme.colors.lightGrey,
							},
						},
					},
				}}
			>
				<ListItemLabel>{props.label}</ListItemLabel>
			</ListItem>
		</div>
	)
}
